<template>
  <div class="home">
<Navigation/>
    <div class="section bg-primary-3">
    <div class="container text-center">
      <h1>Cover Photos</h1>
      <div class="text-large">Level up your branding by having a stylish cover photo!</div>
    </div>
  </div>
<Templates Category="cover-photos" />


  </div>

</template>

<script>
import Navigation from "@/components/Navigation.vue"
import Templates from "@/components/TemplateCollection.vue"

export default {
  name: "home",
  components: {
    Navigation,
    Templates
  }
};
</script>
